:root {
  --header-height: 3rem;
  --navbar-height: 2rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.qwitcher-grypen-regular {
  font-family: "Qwitcher Grypen", cursive;
  font-weight: 400;
  font-style: normal; 
}

.qwitcher-grypen-bold {
  font-family: "Qwitcher Grypen", cursive;
  font-weight: 700;
  font-style: normal;
}