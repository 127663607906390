.main {
  margin: 0;
  background-image: url('./images/1.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
}
/* .main-image {
  width: 100%;
  height: 100vh;
} */
.gallery-content {
  color: #0a090c;
  width: 40%;
  padding-top: 10%;
  margin-right: 3rem;
  /* position: absolute;
  top: 40%;
  left: 10%; */
  text-align: center;
}
.gallery-content .big {
  font-size: 56px;
  color: #f150bf;
  font-family: Arial, Helvetica, sans-serif;
}
.gallery-content .small {
  font-size: 22px;
  letter-spacing: 2px;
}
.gallerytext{
    font-size: larger;
    margin: 15px;
}

@media screen and (max-width: 1024px) and (min-width: 300px) {
  .main-image {
    height: 50vh;
  }
  .gallery-content {
    padding-top: 15.5rem;
    padding-left: 5px;
  }
  .gallery-content .big {
    font-size: 20px;
  }
  .gallery-content .small {
    font-size: 15px;
  }
  .gallery {
    font-size: 15px;
  }
}
