@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 
* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
 
.container_bg {
    height: 100vh;
    width: 100vw;
    background-image: url('./Assets/back1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
 
    align-items: center;
}
.inner_ctr {
 
    box-shadow: 0 0 20px rgba(164, 159, 159, 0.2);
    width: 710px;
    height: 340px;
    /* background-color: transparent; */
    background-color: rgba(0, 0, 0, 0.529);
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.113);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    padding: 10px 40px;
 
}
.innermost_ctr {
    display: flex;
    flex-direction: row;
}
 
.text_space {
 
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* color: white;    */
}
 
.input_one {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    color: white;
    height: 40px;
    width: 300px;
    background-color: black;
    border: 1px solid transparent;
    font-size: 18px;
    font-weight: 400;
    padding-left: 20px;
}
 
.apt_heading {
    margin-left: 30px;
    color: white;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 10px;
}
 
#two {
 
    margin-left: 20px;
}
 
.btn_one {
    font-size: large;
    font-weight: 600;
    cursor: pointer;
    width: 625px;
    height: 50px;
    color: white;
    background-color: rgb(241, 167, 76);
    margin-top: 30px;
}
 
@media screen and (max-width :500px) {
 
 
    .apt_heading {
   
        color: white;
        margin-left: 45px;
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 10px;
    }
    .input_one {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        color: white;
        height: 40px;
        width: 200px;
        background-color: black;
        border: 1px solid transparent;
        font-size: 18px;
        font-weight: 400;
        padding-left: 10px;
    }
    .container_bg {
        height: 100vh;
        width: 100vw;
        background-image: url('./Assets/back1.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #one {
 
        margin-left: 10px;
    }
    #two{
        margin-left: 10px;
 
    }
 
    .inner_ctr {
 
        box-shadow: 0 0 20px rgba(164, 159, 159, 0.2);
        width: 310px;
        height: 500px;
        background-color: rgba(0, 0, 0, 0.529);
        color: white;
        border: 2px solid rgba(255, 255, 255, 0.113);
        backdrop-filter: blur(30px);
        border-radius: 20px;
        margin-left: 25px;
        /* padding: 10px 40px; */
 
    }
 
    .innermost_ctr {
        display: flex;
        flex-direction: column;
 
 
    }
    .btn_one {
        font-size: large;
        font-weight: 600;
        cursor: pointer;
        width: 210px;
        height: 50px;
        color: white;
        background-color: rgb(241, 167, 76);
        margin-top: 30px;
        margin-left: 15px;
    }
}